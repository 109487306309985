import React from 'react'

const About2 = () => {
  return (
    <>
        <section id="ait" className="discover-section">
        <div className="discover-left">
          <div className="discover-content">
            <h2>
              Discover Innovative Solutions with{" "}
              <span className="discover-span">Infogainsoft</span>
            </h2>
            <h3>
              Unveil New Horizons in Technology and Business Transformation
            </h3>
            <p>
              Welcome to Infogainsoft's Discover page, where innovation and
              technology converge to create endless possibilities for your
              business. As a leading IT solutions provider, we invite you to
              explore the cutting-edge world of Infogainsoft, where every click
              opens doors to transformative solutions and unprecedented
              opportunities.
            </p>
          </div>
        </div>
        <div className="discover-right">
          <img src="discover.png" alt="Discover" className="discover-image" />
        </div>
      </section> 
    </>
  )
}

export default About2