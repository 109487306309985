import React from 'react'
import '../Aboutus/Expertise.css'
import {Link} from 'react-router-dom'
import { FaArrowRight } from 'react-icons/fa';
import { FaFacebook,FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import Founder from './Founder';
const Expertise = () => {
    const expertiseData = [
      { id: 1, logo: 'wd.png', title: 'Web Development', description: 'Building responsive and modern websites.' },
      { id: 2, logo: 'ma.png', title: 'Mobile App Development', description: 'Creating intuitive mobile applications.' },
      { id: 3, logo: 'uu.png', title: 'UI/UX Design', description: 'Designing user-friendly and engaging interfaces.' },
      { id: 4, logo: 'dm.png', title: 'Digital Marketing', description: 'Promoting brands through digital channels.' },
      { id: 5, logo: 'so.png', title: 'SEO Optimization', description: 'Enhancing visibility on search engines.' },
      { id: 6, logo: 'cs.png', title: 'Cloud Solutions', description: 'Providing scalable cloud infrastructure.' },
    ];

    const socialCards = [
      { icon: <FaFacebook />, name: 'Facebook' , url: 'https://www.facebook.com/people/Infogainsoft/100082269813889/'},
 
      { icon: <FaInstagram />, name: 'Instagram', url: 'https://www.instagram.com/infogainsoft/?igsh=OWxqMmhrYnUxaXN1&utm_source=qr' },
      { icon: <FaLinkedin />, name: 'LinkedIn', url: 'https://www.linkedin.com/company/infogainsoft/mycompany/' },
    ];
  
    return (
        <>




{/*-
      <section className="expertise">
        <h2>Our Area Of Expertise</h2>
        <div className="expertise-grid">
          {expertiseData.map((item) => (
            <div key={item.id} className="expertise-card">
              <img src={item.logo} alt={item.title} className="expertise-logo" />
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </section>
      -*/}


<section className="ca-container">
      <div className="ca-content">
        <h2 className="ca-heading">Career at Infogainsoft</h2>
        <p className="ca-text">
          Join us on our journey to innovate and make a difference. At Infogainsoft, we offer a collaborative and inspiring work environment, focusing on growth and excellence.
        </p>
        <Link to="/career">
        <button className="ca-button">
          Visit our career page <FaArrowRight className="ca-arrow" />
        </button>
        </Link>
      </div>
    </section>


    <div className="soco-section">
      <div className="soco-header">
      
        <h2>Follow us for the latest updates</h2>
      </div>
      <div className="soco-grid">
        {socialCards.map((card, index) => (
          <a 
            key={index} 
            href={card.url} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="soco-card"
          >
            <div className="soco-icon">{card.icon}</div>
            <div className="soco-content">
              <p>{card.name}</p>
              <p className="soco-brand">Infogainsoft</p>
            </div>
          </a>
        ))}
      </div>
    </div>







    <div className="teach-container">
      <div className="teach-content">
        <h1 className="teach-logo">Infogainsoft</h1>
        <div className='sp'>
        <span className="teach-tagline">Accelerating</span>
        <span className="teach-tagline">Growth™</span>
        </div>
      </div>
      <div className="teach-buttons">
      <Link to="/contactus"> <button className="ca-button">Contact us →</button></Link> 
       
      </div>
    </div>
    </>
    );
  };
  
  export default Expertise;