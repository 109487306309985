import React from "react";
import "../Aboutus/About.css";
import Founder from "./Founder";
import Expertise from "./Expertise";
import About2 from "./About2";
const About = () => {
  return (
    <>
    {/*-
    <Founder/>
    -*/}
     <About2/>
     {/*-
      <Expertise/>
      -*/}
    </>
  );
};

export default About;
