import React, { useState } from "react";
import "../Home/home.css";
import { Link } from "react-router-dom";
import { BsFillHospitalFill } from "react-icons/bs";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { GrTechnology } from "react-icons/gr";
import { FaExplosion } from "react-icons/fa6";
import { MdOutlineWifiCalling3 } from "react-icons/md";
import { RiHotelFill } from "react-icons/ri";
import { TfiLayoutMediaCenterAlt } from "react-icons/tfi";
import { FaCartArrowDown } from "react-icons/fa";
import { MdOutlineSocialDistance } from "react-icons/md";
import { GiAutomaticSas } from "react-icons/gi";
import { MdCastForEducation } from "react-icons/md";
import { MdRealEstateAgent } from "react-icons/md";
import { GiSellCard } from "react-icons/gi";
import { GiTelepathy } from "react-icons/gi";
import Contactus from "../Contact/contactus";
import About from "../Aboutus/About";
import Services from "../Services/Services";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Chat from "../Chatbox/Chat";
import Association from "../Association/Association";
import About2 from "../Aboutus/About2";
const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const cardData = [
    {
      imageUrl: "5677.jpg",
      text: "Infogainsoft: Leading the way in innovative technology solutions for a digital world.",
      by: "Ravi Sharma",
    },
    {
      imageUrl: "https://img.freepik.com/free-photo/male-dj-grey-suit-with-headphones-neck_114579-15958.jpg?t=st=1719310165~exp=1719313765~hmac=4841a892162515bc6d3de39f7b7442bc2941c0a3021f96b37853a671e63481de&w=900",
      text: "Infogainsoft: Delivering excellence through bespoke software solutions and services.",
      by: "Arjun Patel",
    },
    {
      imageUrl: "https://img.freepik.com/free-photo/beautiful-male-half-length-portrait-isolated-white-studio-background-young-emotional-hindu-man-blue-shirt-facial-expression-human-emotions-advertising-concept-standing-smiling_155003-25250.jpg?t=st=1719309681~exp=1719313281~hmac=cc0f3d6239604d3d867b5c6f8ab6654bf9187268378f0d7f9c078dadb6b1fc4e&w=900",
      text: "Infogainsoft: Empowering businesses with strategic consulting and tailored IT services.",
      by: "Vikram Mehta",
    },
    {
      imageUrl: "https://img.freepik.com/free-photo/happy-smiling-handsome-man-sitting-chair_114579-79373.jpg?t=st=1719310233~exp=1719313833~hmac=f7faa0dbc1c9a8e44ef74996324012c8c405bf9b5012fa5d6d43e1c6191c34af&w=900",
      text: "Infogainsoft: Driving business transformation with cutting-edge technology and innovation.",
      by: "Rahul Gupta",
    },
    {
      imageUrl: "https://img.freepik.com/free-photo/medium-shot-smiley-man-posing_23-2148920610.jpg?t=st=1719311068~exp=1719314668~hmac=c8a85d420543291441c37cfeb37bac720d533c528a3266ea1f038a0f41b7b8e7&w=826",
      text: "Infogainsoft: Transforming digital landscapes with customized solutions and expertise.",
      by: "Amit Kumar",
    },
    {
      imageUrl: "https://img.freepik.com/premium-photo/portrait-young-handsome-indian-businessman-city_251136-60145.jpg?w=900",
      text: "Infogainsoft: Your trusted partner for comprehensive digital transformation strategies.",
      by: "Suresh Nair",
    },
    {
      imageUrl: "https://img.freepik.com/free-photo/portrait-good-looking-smiling-arabic-man-suit-attractive-young-businessman-with-beard-moustache-looking-camera-portrait-international-beauty-concept_74855-21597.jpg?t=st=1719311157~exp=1719314757~hmac=7aba2ffed3fdf8242cac2c2156166158c4eecc06d8c9cc698d60734741d9a6a7&w=900",
      text: "Infogainsoft: Pioneering innovative solutions for seamless business operations.",
      by: "Manish Joshi",
    },
    {
      imageUrl: "https://img.freepik.com/free-photo/couple-drinking-wine-talking-table_23-2147770760.jpg?t=st=1719311205~exp=1719314805~hmac=644dda6caa93fa61d5191fcca252fd932e9281fe2a9f35f32ad0b0b3d1c89adb&w=900",
      text: "Infogainsoft: Enhancing business efficiency with innovative digital solutions.",
      by: "Kunal Desai",
    },
  ];
  

  const renderCards = () => {
    const totalCards = cardData.length;
  
  
    let adjustedIndex = currentIndex % totalCards;
  
  
    const indices = [
      (adjustedIndex - 1 + totalCards) % totalCards,
      adjustedIndex,
      (adjustedIndex + 1) % totalCards,
      (adjustedIndex + 2) % totalCards
    ];
  
    return indices.map(index => cardData[index]);
  };
  return (
    <>
      <section id="hit" className="poster-section-main">
        <div className="poster-section">
          <div className="first-left">
            <h2 className="first-left-heading-1">
              <span className="first-left-heading-1-span">Unlock</span> Your
              Potential with Our Solutions...
            </h2>
            <p className="first-left-heading">
              Infogainsoft is your trusted partner in navigating the
              complexities of the digital landscape. With a focus on innovation
              and a dedication to excellence, we provide tailored IT solutions
              that empower businesses to thrive in the modern world. From
              software development to digital transformation initiatives, we
              leverage cutting-edge technologies and industry expertise to
              deliver results that propel your organization forward. Partner
              with Infogainsoft and unlock the full potential of your digital
              journey.
            </p>
            <Link to="/contactus">
            <button>Contact Us</button>
            </Link>
          </div>
          <div className="second-right">
            <div class="main">
              <div class="big-circle">
                <div class="icon-block">
                  <img
                    src="https://www.yudiz.com/codepen/animated-portfolio/web-dev-icon.png"
                    alt="web design icon"
                  />
                </div>
                <div class="icon-block">
                  <img
                    src="https://www.yudiz.com/codepen/animated-portfolio/game-design-icon.png"
                    alt="game design icon"
                  />
                </div>
                <div class="icon-block">
                  <img
                    src="https://www.yudiz.com/codepen/animated-portfolio/game-dev-icon.png"
                    alt="game dev icon"
                  />
                </div>
                <div class="icon-block">
                  <img
                    src="https://www.yudiz.com/codepen/animated-portfolio/ui-ux-icon.png"
                    alt="ui-ux icon"
                  />
                </div>
              </div>
              <div class="circle">
                <div class="icon-block">
                  <img
                    src="https://www.yudiz.com/codepen/animated-portfolio/app-icon.png"
                    alt="app icon"
                  />
                </div>
                <div class="icon-block">
                  <img
                    src="https://www.yudiz.com/codepen/animated-portfolio/blockchain-icon.png"
                    alt="blockchain icon"
                  />
                </div>
                <div class="icon-block">
                  <img
                    src="https://www.yudiz.com/codepen/animated-portfolio/arvr-icon.png"
                    alt="ar-vr icon"
                  />
                </div>
                <div class="icon-block">
                  <img
                    src="https://www.yudiz.com/codepen/animated-portfolio/artificial-intelligence-icon.png"
                    alt="artificial intelligence icon"
                  />
                </div>
              </div>
              <div class="center-logo">
                <img
                  className="center-logo-img"
                  src="infogain.jpeg"
                  alt="logo"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="ait" className="discover-sections">
        <About2/>
      </section>
      <section id="sit" className="ours-services">
        <Services />
      </section>
    
      <section className="awards-section-main">
        <h1 className="text-center award-heading">
          At <span className="award-heading-span">Infogainsoft</span>,
          Excellence Speaks Volumes
        </h1>
        <p className="text-center award-text">
         

          At Infogainsoft, our relentless pursuit of excellence and innovation
          has garnered the trust of industry leaders worldwide. From pioneering
          software solutions to cutting-edge cloud technologies and strategic
          digital initiatives, our track record of success resonates across
          sectors. Join the league of forward-thinking enterprises who have
          harnessed the transformative prowess of Infogainsoft, and elevate your
          business to new heights of success today.
        </p>
 {/*-
        <div class="sliding-image-container">
    <div id="sliding-image1" class="sliding-image">
        <img src="e1.png" alt="brosid" class="partner-logo"/>
        <img src="e2.png" alt="direccion" class="partner-logo"/>
        <img src="e3.png" alt="mvp" class="partner-logo"/>
        <img src="e4.png" alt="thinkbuy" class="partner-logo"/>
    </div>
    <div id="sliding-image2" class="sliding-image">
        <img src="e5.png" alt="neotown" class="partner-logo"/>
        <img src="e6.png" alt="rg" class="partner-logo"/>
        <img src="e7.png" alt="m3m" class="partner-logo"/>
        <img src="e8.png" alt="click4serve" class="partner-logo"/>
    </div>
    <div id="sliding-image3" class="sliding-image">
        <img src="e9.png" alt="godrejjardania" class="partner-logo"/>
        <img src="e10.png" alt="lemore" class="partner-logo"/>
        <img src="tcs.png" alt="demo" class="partner-logo"/>
        <img src="enfosys.png" alt="demo" class="partner-logo"/>
    </div>
</div>
-*/}
<div className="photo-wrapper" id="photoWrapper">
  <div className="photo">
    <img alt="logo 1" src="e1.png" />
  </div>
  <div className="photo">
    <img alt="logo 2" src="e2.png" />
  </div>
  <div className="photo">
    <img  alt="logo 3" src="e3.png" />
  </div>
  <div className="photo">
    <img  alt="logo 4" src="e4.png" />
  </div>
  <div className="photo">
    <img alt="logo 5" src="e5.png" />
  </div>
  <div className="photo">
    <img alt="logo 6" src="e6.png" />
  </div>
  <div className="photo">
    <img alt="logo 7" src="e7.png" />
  </div>
  <div className="photo">
    <img alt="logo 8" src="e8.png" />
  </div>
  <div className="photo">
    <img alt="logo 9" src="logo-f.png" />
  </div>
  <div className="photo">
    <img alt="logo 10" src="e10.png" />
  </div>
 
</div>


      </section>

<Association/>
       {/* --
      <section className="trusted-section">
        <h1 className="trusted-head">
          Trusted by <span className="trusted-head-span">Infogainsoft</span>
        </h1>
        <p className="trusted-text text-center">
        InfogainSoft has consistently been recognized for its commitment to
          innovation, quality, and client satisfaction. Our dedication to
          pushing boundaries and delivering top-notch solutions has garnered
          acclaim from industry experts and organizations alike. Explore our
          accolades below to see why InfogainSoft stands out in the IT
          landscape.
        </p>
        <div className="carousel">
          <div className="cards">
            <div className="slider">
              {renderCards().map((card, index) => (
                <div key={index} className="card">
                  <img src={card.imageUrl} alt={`Card ${index}`} />
                  <p className="card-text text-center">{card.text}</p>
                  <p className="card-text2 text-center">- {card.by}</p>
                </div>
              ))}
             
              {renderCards().map((card, index) => (
                <div key={index + renderCards().length} className="card">
                  <img src={card.imageUrl} alt={`Card ${index}`} />
                  <p className="card-text text-center">{card.text}</p>
                  <p className="card-text2 text-center">- {card.by}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      - */}
      <section className="cover-industries">
        <div className="cover-industries-section">
          <div className="text-section">
            <h1 className="section-heading">
              <span className="section-heading-span">Industries</span> We Cover
            </h1>
            <p className="section-text">
              Infogainsoft excels in crafting bespoke IT solutions for a
              multitude of industries, including healthcare, finance, retail,
              manufacturing, education, and hospitality. Our expertise lies in
              developing tailored software applications and platforms that
              streamline operations and drive growth in each sector. From
              optimizing patient care to revolutionizing financial transactions,
              our solutions are meticulously crafted to address the unique
              challenges and opportunities of every industry we serve. Partner
              with InfoGainSoft to harness the power of technology and propel
              your business forward in today's dynamic marketplace.
            </p>
          </div>
          <div className="industry-container">
            <div className="industry-icons">
              <div className="one-indus">
                <BsFillHospitalFill className="icon-idus" />
                <p className="one-indus-text">Healthcare</p>
              </div>
              <div className="one-indus">
                <FaMoneyBillTrendUp className="icon-idus" />
                <p className="one-indus-text">Finance</p>
              </div>
              <div className="one-indus">
                <GrTechnology className="icon-idus" />
                <p className="one-indus-text">Technology</p>
              </div>
              <div className="one-indus">
                <FaExplosion className="icon-idus" />
                <p className="one-indus-text">Non-Profit</p>
              </div>
              <div className="one-indus">
                <TfiLayoutMediaCenterAlt className="icon-idus" />
                <p className="one-indus-text">Media & Entertainment</p>
              </div>
              <div className="one-indus">
                <FaCartArrowDown className="icon-idus" />
                <p className="one-indus-text">E-Commerce</p>
              </div>
              <div className="one-indus">
                <MdOutlineSocialDistance className="icon-idus" />
                <p className="one-indus-text">Social Networking</p>
              </div>
            </div>
            <div className="second-indus">
              <div className="one-indus">
                <GiTelepathy className="icon-idus" />
                <p className="one-indus-text">Telecom</p>
              </div>
              <div className="one-indus">
                <GiAutomaticSas className="icon-idus" />
                <p className="one-indus-text">Automotive</p>
              </div>
              <div className="one-indus">
                <MdCastForEducation className="icon-idus" />
                <p className="one-indus-text">Education</p>
              </div>
              <div className="one-indus">
                <MdRealEstateAgent className="icon-idus" />
                <p className="one-indus-text">Real Estate</p>
              </div>
              <div className="one-indus">
                <GiSellCard className="icon-idus" />
                <p className="one-indus-text">Retail</p>
              </div>
              <div className="one-indus">
                <RiHotelFill className="icon-idus" />
                <p className="one-indus-text">Hospitality</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="cit" className="contactus-sections-">
        <Contactus />
      </section>
      <Chat/>
    </>
  );
};

export default Home;
